import { CustomSelectComponent, CustomSelectDiv } from "./index.style";
import React, { useState } from 'react';


function CustomSelect(props) {
    return (
        <CustomSelectDiv style={props.outerStyle}>
            <CustomSelectComponent onChange={props.onChange} style={props.style}>
                {
                    props.options.map((option, index) => (
                        <option key={index} value={option.value}>{option.text}</option>
                    ))
                }
            </CustomSelectComponent>
        </CustomSelectDiv>
    );
}

export default CustomSelect;