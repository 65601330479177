import styled, { css } from 'styled-components';
import constants from '../../configs/constants';
import fontSizes from '../../configs/fontSizes';
import colors from '../../configs/colors';

const NAVBAR_HEIGHT = 90;
const PseudoElementStyles = css`
    content: '';
    position: absolute;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
`;

const NavbarDiv = styled.div`
    position: fixed;
    top: 0;

    display: flex;
    flex-direction: row;
    justify-content: center;

    width: 100%;
    height: ${NAVBAR_HEIGHT}px;
    background-color: white;
    z-index: 500;
`;

const NavbarInnerDiv = styled.div`
    height: 100%;
    width: ${constants.maxWrapperWidth}px;
    max-width: ${constants.maxWrapperWidth}px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const NavbarMenu = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    border: 1px solid #E0E0E0;
    overflow: hidden;
`;

const NavbarMenuItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 10px 20px;
    margin: 0 5px;
    border: none;
    color: ${props => props.active ? colors.primary : '#E0E0E0'};
    border-radius: 5px;
    cursor: pointer;
    outline: none;
    font-size: ${fontSizes.small}px;
    font-weight: bold;

    // Add the left shape
    &::before {
        content: "";
        position: absolute;
        top: -2px;
        right: -10px;

        width: 0;
        height: 0;
        border: 13px solid #E0E0E0;
        border-right: none;
        border-top: 22px solid transparent;
        border-bottom: 22px solid transparent;
        z-index: 2;
    }

    // Add the right shape
    &::after {
        content: "";
        position: absolute;
        top: -2px;
        right: -9px;

        width: 0;
        height: 0;
        border: 13px solid white;
        border-right: none;
        border-top: 22px solid transparent;
        border-bottom: 22px solid transparent;
        z-index: 2;
    }

    // Adjust the first and last item to not have the shapes
    &:last-child::before, &:last-child::after {
        content: none;
    }
`;

const NavbarMenuItemNumber = styled.div`
    width: 18px;
    height: 18px;
    margin-right: 5px;

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${fontSizes.small}px;

    border-radius: 100px;
    border: 1px solid black;
    border-color: inherit;
`;

export { NavbarDiv, NavbarInnerDiv, NavbarMenu, NavbarMenuItem, NavbarMenuItemNumber }