import networking from "../configs/networking"

async function processComments(comment) {
    const URL = networking.apiAddress + 'process_comment';

    const OPTIONS = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        },
        body: JSON.stringify({
            'unprocessed_comments': comment
        })
    };

    const response = await fetch(URL, OPTIONS);
    if (!response.ok) {
        throw new Error(`API call failed: ${response.statusText}`);
    }

    return response.json();
}

async function transcribeAudio(audio, language) {
    const URL = networking.apiAddress + 'transcribe';
    // Wrap the audio file in form data
    const audioBlob = await fetch(audio).then(r => r.blob());
    const formData = new FormData();
    formData.append('audio_file', audioBlob, 'recording.webm');
    formData.append('language', language);

    const OPTIONS = {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        },
        body: formData
    };

    const response = await fetch(URL, OPTIONS);
    if (!response.ok) {
        throw new Error(`API call failed: ${response.statusText}`);
    }

    return response.json();
}

async function processAnnotationAnswers(comment) {
    const URL = networking.apiAddress + 'review';
    const OPTIONS = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        },
        body: JSON.stringify({
            'notes_with_feedback': comment
        })
    };

    const response = await fetch(URL, OPTIONS);
    if (!response.ok) {
        throw new Error(`API call failed: ${response.statusText}`);
    }

    return response.json();
}

async function login(username, password) {
    const URL = networking.apiAddress + 'auth/login';
    const OPTIONS = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            'username': username,
            'password': password
        })
    };

    const response = await fetch(URL, OPTIONS);
    if (!response.ok) {
        throw new Error(`API call failed: ${response.statusText}`);
    }

    const data = await response.json();

    // If the login is successful, store the token
    if (data.access_token) {
        localStorage.setItem('jwtToken', data.access_token);
    }

    return data;
}

async function validateToken() {
    const URL = networking.apiAddress + 'auth/validate';
    const OPTIONS = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        }
    };

    const response = await fetch(URL, OPTIONS);

    if (response.status === 200) {
        return true;
    } else {
        return false;
    }
}

export { processComments, transcribeAudio, processAnnotationAnswers, login, validateToken }