import styled from 'styled-components';
import colors from '../../configs/colors';
import fontSizes from '../../configs/fontSizes'; 
import constants from '../../configs/constants';

const CustomButtonComponent = styled.button`
    display: inline-flex;
    justify-content: center;
    align-items: center;

    background-color: ${colors.primary};
    color: white;
    font-size: ${fontSizes.regular}px;
    border-radius: ${constants.borderRadius}px;
    outline: none;
    border: none;

    padding: 8px 15px;

    &:hover {
        cursor: pointer;
        filter: brightness(90%);
    }

    &:disabled {
        opacity: 0.4;
        cursor: not-allowed;
    }
`;

export { CustomButtonComponent }