import React, { useEffect, useState } from 'react';
import Wrapper from '../../components/Wrapper';
import './style.css';
import Task from '../../components/Task';
import { MainContainer, LeftSide, RightSide, SuggestionsContainer, SuggestionWindow, SuggestionText, ProcessedCommentContainer, ProcessedCommentWrapper, SideHeadline, SideContentWrapper } from './index.style';
import TaskTitle from '../../components/TaskTitle';
import CustomButton from '../../components/CustomButton';
import CustomTextArea from '../../components/CustomTextArea';
import CommentHighlight from '../../components/CommentHighlight';
import * as api from '../../utils/api';
import Container from '../../components/Container';
import Navbar from '../../components/Navbar';
import CustomInput from '../../components/CustomInput';
import CustomSelect from '../../components/CustomSelect';

const MENU_ITEMS = [
    {name: 'Disassemble'},
    {name: 'Clean'},
    {name: 'Check'},
    {name: 'Repair'},
    {name: 'Assemble'}
]

const TRANSCRIPT_LANGUAGES = [
    { value: 'nl', text: 'Dutch'},
    { value: 'en', text: 'English' },
    { value: 'fr', text: 'French'}
]

function Main() {
    const [selectedLanguage, setSelectedLanguage] = useState('nl');

    // Define currently selected step (first by default)
    const [selectedStep, setSelectedStep] = useState(MENU_ITEMS[0].name);

    const [unprocessedComments, setUnprocessedComments] = useState('');
    const [processedComments, setProcessedComments] = useState('');

    const [selectedHighlight, setSelectedHighlight] = useState(null);
    const [hoveredHighlight, setHoveredHighlight] = useState(null);

    // Parts are all parts of the text, including the annotated ones
    // Annotations are only the annotated parts
    const [parts, setParts] = useState([]);
    const [annotations, setAnnotations] = useState([]);

    const [isRecording, setIsRecording] = useState(false);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [audioURL, setAudioURL] = useState('');

    const [isTrascribing, setIsTranscribing] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);

    useEffect(() => {
        // Check token, redirect to login if doesn't exist
        if (!localStorage.getItem('jwtToken')) {
            window.location.href = '/login';
        }

        // Check if the user is logged in
        api.validateToken().then(r => {
            if (!r)
                window.location.href = '/login';
        }).catch(e => {});

         // Ask for microphone access
         if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
                const mimeType = 'audio/webm;codecs=opus';
                const newMediaRecorder = new MediaRecorder(stream, {mimeType});
                setMediaRecorder(newMediaRecorder);

                newMediaRecorder.ondataavailable = e => {
                    const audioData = e.data;
                    const audioSrc = URL.createObjectURL(audioData);
                    setAudioURL(audioSrc)
                };
            });
        }
    }, []);

    useEffect(() => {
        if (audioURL) {
            console.log('Audio URL updated:', audioURL);
            transcribeAudio();
        }
    }, [audioURL]);

    const startRecording = () => {
        if (mediaRecorder) {
            if (!isRecording) {
                setIsRecording(true);
                mediaRecorder.start();
                console.log(mediaRecorder.state);
                console.log("recorder started");
            } else {
                setIsRecording(false);
                mediaRecorder.stop();
                console.log(mediaRecorder.state);
                console.log("recorder stopped");
            }
        }
    };

    /*
        - This function parses the annotated parts of the text and prepares them for rendering
        - It returns an array of (all) parts of the text and an array of annotations (annotated parts of the text)
    */
    const parseText = (apiText) => {
        // A regular expression to find the annotated parts
        const regex = /\{"text": "(.*?)", "question": "(.*?)", "header": "(.*?)", "options": \[(.*?)\]\}/g;
        let parts = [];
        let annotations = [];
        let lastIndex = 0;
        
        apiText.replace(regex, (match, text, question, header, options, index) => {
            // Add the text before the annotation
            if (index > lastIndex) {
                parts.push({ text: apiText.slice(lastIndex, index) });
            }
    
            const annotationAnswer = '';
            const predefinedAnswer = '';
            options = JSON.parse("[" + options + "]");
        
            // Add the annotated text
            parts.push({ text, question, header, options, isAnnotated: true, index, annotationAnswer, predefinedAnswer});
            annotations.push({ text, question, header, options, index, annotationAnswer, predefinedAnswer });
        
            // Update lastIndex to the end of the current match
            lastIndex = index + match.length;
        });
        
        // Add any remaining text after the last match
        if (lastIndex < apiText.length) {
            parts.push({ text: apiText.slice(lastIndex) });
        }
        
        return { parts, annotations };
    }

    /*
        - This function is called when the user clicks on the "Transcribe" button
        - It sends the audio file to the backend
        - The backend transcribes the audio and returns the unprocessed comments
        - The transcription is displayed on the left side of the UI
    */
    const transcribeAudio = () => {
        console.log('transcribing...');
        setIsTranscribing(true);
        api.transcribeAudio(audioURL, selectedLanguage).then(r => {
            setUnprocessedComments(r['unprocessed_comments']);
            setIsTranscribing(false);
        })
    };

    /*
        - This function is called when the user clicks on the "Process comments" button
        - It sends the unprocessed comments to the backend
        - The backend processes the comments and returns the processed comments with annotations
        - Annotations are parsed and saved in the state to display them in the UI
    */
    const processComments = () => {
        setIsProcessing(true);

        api.processComments(unprocessedComments).then(r => {
            let comment = r['comments'];
            console.log(comment)

            const parsedText = parseText(comment);
            setParts(parsedText.parts); 
            setAnnotations(parsedText.annotations); 

            setProcessedComments(comment);
            setIsProcessing(false);
        });
    }

    /*
        - This function is called when the user clicks on the "Confirm answers" button
        - It sends the annotated answers to the backend in specific format
        - The backend processes the answers and returns the updated comments
    */
    const sendAnnotationAnswers = () => {
        setIsProcessing(true);

        let notesWithFeedback = '';
        parts.forEach(part => {
            if(part.isAnnotated) {
                // Add JSON with answer as string
                notesWithFeedback += `{"text": "${part.text}", "question": "${part.question}", "answer": "${part.annotationAnswer}"}`;
            } else {
                notesWithFeedback += part.text;
            }
        });
        
        api.processAnnotationAnswers(notesWithFeedback).then(r => {
            const parsedText = parseText(r['reviewed_notes']);
            setParts(parsedText.parts); 
            setAnnotations(parsedText.annotations); 
            setIsProcessing(false);
        });
    }
    
    return (
        <Container>
            <Navbar steps={MENU_ITEMS} selectedStep={selectedStep} onStepChange={newStep => setSelectedStep(newStep)} onSignOut={() => {
                // Remove token and redirect to login
                localStorage.removeItem('jwtToken');
                window.location.href = '/login';
            }} />

            <Wrapper style={{ height: '100%' }}>
                <MainContainer>
                    {/* Left side of the container - written/transcripted notes */}
                    <LeftSide>
                        <SideHeadline>Your notes</SideHeadline>
                        <SideContentWrapper>
                            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: 15, marginBottom: 15 }}>
                                {/* Language select */}
                                <CustomSelect
                                    options={TRANSCRIPT_LANGUAGES}
                                    onChange={e => setSelectedLanguage(e.target.value)}
                                    value={selectedLanguage}
                                />

                                <CustomButton style={{marginLeft: 10}} disabled={isTrascribing} text={isRecording ? 'Stop recording' : 'Record notes'} iconName={isRecording ? 'FaRegStopCircle' : 'FaMicrophone'} onClick={startRecording}/>
                            </div>
                            <CustomTextArea value={unprocessedComments} onChange={e => setUnprocessedComments(e.target.value)} placeholder='Unprocessed comments' style={{ width: '100%', flexGrow: 1, marginBottom: 15 }} />
                        </SideContentWrapper>
                    </LeftSide>

                    {/* Right side of the container - SAP input */}
                    <RightSide>
                        <SideHeadline>SAP input</SideHeadline>
                        <SideContentWrapper>
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 15, marginBottom: 15}}>
                                <CustomButton style={{marginLeft: 'auto', marginRight: 10}} disabled={isProcessing} text='Process comments' iconName='FaSync' onClick={() => { processComments(); }} />
                                <CustomButton disabled={annotations.length == 0 || isProcessing} text='Confirm answers' iconName='FaCheck' onClick={() => { sendAnnotationAnswers(); }} />
                            </div>

                            <ProcessedCommentContainer style={{ marginBottom: 15, flexGrow: 1, whiteSpace: 'pre-wrap' }}>
                                {/* The processed comments are rendered here, including their highlighted clickable parts */}
                                <ProcessedCommentWrapper>
                                    {parts.map((part, index) => {
                                        if(part.isAnnotated) {
                                            return <CommentHighlight
                                                key={'highlight_' + index}
                                                selected={part.index === selectedHighlight || part.index === hoveredHighlight}
                                                onMouseEnter={() => { setHoveredHighlight(part.index); }}
                                                onMouseLeave={() => { setHoveredHighlight(null); }}
                                            >
                                                {part.text}
                                            </CommentHighlight>
                                        } else {
                                            return <span key={index}>{part.text}</span>
                                        }
                                        })}
                                </ProcessedCommentWrapper>

                                <SuggestionsContainer>
                                    {
                                        // The annotations parts are rendered here, including the input fields for the answers
                                        parts.map((part, index) => {
                                            if(!part.isAnnotated) return null;
                                            
                                            // Initially only the predefined answer is rendered
                                            let inputComponents = [
                                                <CustomSelect
                                                    key={'select_' + index}
                                                    options={part.options.map(option => ({ value: option, text: option }))}
                                                    onChange={e => {
                                                        let newParts = [...parts];
                                                        newParts[index].predefinedAnswer = e.target.value;
                                                        if (e.target.value.toLowerCase().indexOf('other') >= 0 || e.target.value.toLowerCase() === 'provide removal reason')
                                                            newParts[index].annotationAnswer = '';
                                                        else
                                                            newParts[index].annotationAnswer = e.target.value;
                                                        setParts(newParts);
                                                    }}
                                                    value={part.annotationAnswer}
                                                    outerStyle={{ width: '100%', height: 30}}
                                                    style={{ width: '100%', height: 30, padding: 0, paddingLeft: 10, paddingRight: 25}}
                                                ></CustomSelect>
                                            ];

                                            // If the predefined answer contains 'other' or "provide removal reason", add an input field for the answer
                                            if(part.predefinedAnswer.toLowerCase().indexOf('other') >= 0 || part.predefinedAnswer.toLowerCase() === 'provide removal reason') {
                                                inputComponents.push(
                                                    <CustomInput
                                                        key={'input_' + index}
                                                        value={part.annotationAnswer}
                                                        onChange={e => {
                                                            let newParts = [...parts];
                                                            newParts[index].annotationAnswer = e.target.value;
                                                            setAnnotations(newParts);
                                                        }}
                                                        placeholder='Answer' style={{ width: 'calc(100% - 20px)', height: 30 }}
                                                    />             
                                                )
                                            }
                                            return (
                                                <SuggestionWindow key={'suggestion_' + index}
                                                        onMouseEnter={() => { setHoveredHighlight(part.index); }}
                                                        onMouseLeave={() => { setHoveredHighlight(null); }}
                                                    >
                                                    <SuggestionText>{part.question}</SuggestionText>
                                                    <div style={{display: 'flex', flexDirection: 'column', gap: 5, alignItems: 'center'}}>
                                                        {inputComponents}
                                                    </div>
                                                </SuggestionWindow>
                                            )
                                        })
                                    }
                                </SuggestionsContainer>
                            </ProcessedCommentContainer>
                        </SideContentWrapper>
                        
                    </RightSide>
                </MainContainer>
            </Wrapper>
        </Container>
    );
}

export default Main;
