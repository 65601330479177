import { CustomButtonComponent } from "./index.style";
import * as Icons from "react-icons/fa";

function CustomButton(props) {
    // Get icon component by its name
    const IconComponent = Icons[props.iconName];

    return (
        <CustomButtonComponent {...props}>
            {props.text}
            {/* If such icon component doesn't exist, show nothing */}
            { IconComponent ? <IconComponent style={{ marginLeft: 5 }} /> : null }
        </CustomButtonComponent>
    );
}

export default CustomButton;