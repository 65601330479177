import styled from 'styled-components';
import colors from '../../configs/colors';
import constants from '../../configs/constants';
import fontSizes from '../../configs/fontSizes';

// Width of the tasks list (left side of the container), in percentages of the container width
const LEFT_SIDE_PCT_WIDTH = 60;

const SIDE_CONTENT_PADDING_HORIZONTAL = 25;
const SIDE_CONTENT_PADDING_BOTTOM = 30;

const MainContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 75%;
    margin-top: 135px;
    background-color: transparent;
`;

const LeftSide = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
    margin-right: 20px;
    background-color: white;
    height: 100%;
    box-shadow: 0px 1px 3px rgba(0,0,0,0.1);
    border-radius: 8px;
    overflow: hidden;
`;

const RightSide = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: white;

    box-shadow: 0px 1px 3px rgba(0,0,0,0.1);
    border-radius: 8px;
    overflow: hidden;
`;

const SideHeadline = styled.div`
    display: flex;
    min-height: 60px;
    box-shadow: 0px 1px 3px rgba(0,0,0,0.1);
    font-size: ${fontSizes.bigger};
    font-weight: bold;
    padding-left: ${SIDE_CONTENT_PADDING_HORIZONTAL}px;
    align-items: center;
`;

const SideContentWrapper = styled.div`
    width: calc(100% - ${SIDE_CONTENT_PADDING_HORIZONTAL*2}px);
    height: calc(100% - ${SIDE_CONTENT_PADDING_BOTTOM}px - 60px);
    padding-left: ${SIDE_CONTENT_PADDING_HORIZONTAL}px;
    padding-right: ${SIDE_CONTENT_PADDING_HORIZONTAL}px;
    padding-bottom: ${SIDE_CONTENT_PADDING_BOTTOM}px;
    display: flex;
    flex-direction: column;
`;

const ProcessedCommentContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: calc(100% - 40px);
    padding: 15px 20px;
    border-radius: ${constants.borderRadius}px;
    border: 1px solid ${colors.border};
    overflow-y: hidden;
`;

const ProcessedCommentWrapper = styled.div`
    flex-grow: 1;
    font-size: ${fontSizes.regular}px;
    line-height: ${fontSizes.lineHeight.regular}px;
    flexWrap: wrap;
    overflow-y: scroll;
`;

const SuggestionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: scroll;
`;

const SuggestionWindow = styled.div`
    padding: 10px;
    border: 1px solid ${colors.border};
    border-radius: 10px;
    box-shadow: 0px 2px 5px rgba(0,0,0,0.1);
`;

const SuggestionText = styled.div`
    margin-bottom: 10px;
    font-size: ${fontSizes.regular}px;
    color: black;
`;

export { MainContainer, LeftSide, RightSide, SuggestionsContainer, SuggestionWindow, SuggestionText, ProcessedCommentContainer, SideHeadline, SideContentWrapper, ProcessedCommentWrapper }