import styled from 'styled-components';
import constants from '../../configs/constants';

// Paddings add to the whole element width, so recalculation is needed based on the padding values
const WrapperDiv = styled.div`
    position: absolute;
    max-width: ${constants.maxWrapperWidth}px;
    left: 0;
    right: 0;
    margin: auto;

    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
`;

export { WrapperDiv }