import { NavbarDiv, NavbarInnerDiv, NavbarMenu, NavbarMenuItem, NavbarMenuItemNumber } from "./index.style";
import {ReactComponent as Logo} from "../../assets/EM_logo.svg"
import { Link } from "react-router-dom";
import fontSizes from "../../configs/fontSizes";
import colors from "../../configs/colors";

function Navbar(props) {
    return (
        <NavbarDiv>
            <NavbarInnerDiv >
                <Logo width="auto" height={36} style={{ display: 'block', width: 'auto' }} />

                <NavbarMenu>
                    {
                        // Render all menu items and automatically number them
                        props.steps.map((item, ix) => 
                            <NavbarMenuItem
                                key={item.name}
                                active={item.name === props.selectedStep}
                                onClick={() => props.onStepChange(item.name)}
                            >
                                <NavbarMenuItemNumber>{ix+1}</NavbarMenuItemNumber>{item.name}
                            </NavbarMenuItem>
                        )
                    }
                </NavbarMenu>
                
                <div>
                    <Link style={{
                        color: 'black',
                        textDecoration: 'none',
                        opacity: 0.2,
                        fontSize: fontSizes.regular,
                    }} onClick={props.onSignOut}
                    >Sign out</Link>
                </div>
            </NavbarInnerDiv>
        </NavbarDiv>
    );
}

export default Navbar;