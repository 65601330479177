import logo from './assets/logo.svg';
import './App.css';
import {
    createBrowserRouter,
    RouterProvider,
  } from "react-router-dom";
import Main from './views/Main';
import Login from './views/Login';

// All the routes are defined here
const router = createBrowserRouter([
    {
        path: "/",
        element: <Main />,
    },
    {
        path: "/login",
        element: <Login />,
    }
]);

function App() {
    return <RouterProvider router={router} />;
}

export default App;
